<template>
  <v-form class="multi-col-validation">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <strong>{{ $t('lbl.method') }}:</strong> <code>POST</code>
            <br>
            <br>
            <code>
              {{ url }}
            </code>
            <br>
            <strong>JSON - {{ $t('lbl.request') }}</strong>
            <json-viewer
              :value="{'api_key':api_key,
                       'secret_token':secret_token,
                       'page':parseInt(page),
                       'per_page':parseInt(per_page),
                       'date':date}"
              :sort="true"
              :boxed="true"
            ></json-viewer>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <strong>{{ $t('lbl.params') }}</strong>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="page"
              outlined
              :label="$t('lbl.page')"
              hide-details
              class="mb-3"
              dense
              type="number"
              min="1"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="per_page"
              outlined
              :label="$t('lbl.perPage')"
              hide-details
              class="mb-3"
              dense
              type="number"
              min="1"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <vc-date-picker
              v-model="date"
              outlined
              dense
              :min-date="$moment(new Date()).add(1, 'day').toDate()"
              mode="date"
              :model-config="modelConfig"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <v-text-field
                  :label="$t('lbl.date')"
                  outlined
                  dense
                  :value="inputValue"
                  v-on="inputEvents"
                >
                </v-text-field>
              </template>
            </vc-date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-btn
              color="primary"
              block
              dark
              large
              :loading="loading"
              @click="save()"
            >
              <span>{{ $t('btn.send') }}</span>
              <v-icon>{{ icons.mdiUpdate }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="jsonData != null"
        cols="12"
        md="6"
      >
        <strong>JSON - {{ $t('lbl.response') }}</strong>
        <json-viewer
          :value="jsonData"
          :sort="true"
          :boxed="true"
        ></json-viewer>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiArrowLeft, mdiUpdate,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
// import LeafletExample from '@/views/leaflet/Example.vue'

export default {
  components: {

    // LeafletExample,
  },
  data() {
    return {
      isLoading: true,
      jsonFile: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
      },
      loading: false,
      jsonData: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      url: `${process.env.VUE_APP_API_URL}car/list`,
      api_key: sessionStorage.getItem('api_key'),
      secret_token: sessionStorage.getItem('secret_token'),
      page: 1,
      per_page: 10,
      date: this.$moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
    }
  },

  methods: {
    setJson(e) {
      if (e) {
        this.readFile(e)
      } else {
        this.jsonData = null
      }
    },
    readFile(file) {
      const reader = new FileReader()
      reader.onload = e => {
        // eslint-disable-next-line no-unused-vars
        this.jsonData = JSON.parse(e.target.result)
      }
      reader.readAsText(file)
    },
    save() {
      if (this.date) {
        this.loading = true
        this.jsonData = null
        const json = {
          api_key: this.api_key,
          secret_token: this.secret_token,
          page: this.page,
          per_page: this.per_page,
          date: this.date,
        }
        this.axios
          .post('car/list', json)
          .then(response => {
            if (!response.data.success) {
              if (response.data.status === 406) {
                this.$toast.error(this.$t('msg.error.406'))
              }
            }
            this.jsonData = response.data
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.reset())
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reset() {
      this.loading = false
    },
  },
}
</script>
